import React  from 'react';

import CONFIG from '../config';
import $ from 'jquery';
import jQuery from 'jquery';
import "react-datetime/css/react-datetime.css";
import "../index.css";

import { MsalContext } from "@azure/msal-react";

window.jQuery = jQuery;
require("jsgrid");

//SETUP PROD API HOST AS WELL
const API_HOST = CONFIG.API_HOST;

class Notification extends React.Component{
    static contextType = MsalContext;

    constructor(props) {
        super(props);
        this.gridRef = React.createRef();

        this.state = {
            startDate: "",
            endDate: ""
        }
    }

    componentDidMount() {
        let dc=this;

        const complete = '<i style="color:#090;" class="fas fa-check"></i>';
        const incomplete = '<span style="color:#F00;font-weight:bold;">X</span>';

        $(window).bind('resize', function() {
            // console.log("WINDOW", window.innerHeight, window.innerWidth);
            const newHeight = window.innerHeight-150 > 260 ? window.innerHeight-260 : 260;
            if(dc.mainGrid){
                dc.mainGrid.jsGrid("option", "height", newHeight);
            }
        }).trigger('resize');

        const leadZeros = (value) => {
            return ('0' + value).slice(-2)
        }

        this.mainGrid = window.jQuery(this.gridRef.current);
        // console.log("MAKING Notification GRID");
        this.mainGrid.jsGrid({
            height: window.innerHeight-260,
            width: "98%",
     
            filtering: false,
            sorting: true,
            paging: true,
            autoload: true,
            editing: false,
            // inserting: true,

            updateOnResize: true,
     
            deleteConfirm: function(item) {
                return "Are you sure you want to delete this document?"; //update this with data 
            },
     
            controller: {
                loadData: function(item) {
                    return $.ajax({
                        url: API_HOST + "/notification_data",
                        dataType: "json",
                        data: item
                    }).then((notificationResults)=>{
                        return notificationResults;
                    });
                },
    
                deleteItem: function(item) {
                    // console.log("DELETING ITEM: ", item);
                    return $.ajax({
                        type: "DELETE",
                        url: API_HOST + "/notification_data",
                        data: item
                    }).then(function(deleteResult){
                        return deleteResult;
                    });
                },
            },
     
            editItem: function(item) {
                // console.log("item", item)

                var $row = this.rowByItem(item);

                if ($row.length) {
                    dc.editingId = item.id;
                    $('#notificationModalContainer').modal()

                    $('#email').val(item.email);
                    $('#notificationType').val(item.notification_type);

                    this._editRow($row);
                }
            },

            fields: [
                { type: "control", editButton: true, deleteButton: false },
                { type: "control", editButton: false, deleteButton: true },
                { title: "Email", name: 'email', type: "text", width: "auto", css: "tnkGridCell", sortable:true },
                { title: "Notification Type", name: 'notification_type', type: "text", width: "auto", css: "tnkGridCell" },
            ]
    
        });        
    }

    showAddNotification() {
        this.editingId = null;
        this.clearModalFields();
        $('#notificationModalContainer').modal()
    }
    
    validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    }

    notificationIsValid(email) {

        let isValid = this.validateEmail(email)
        let message = isValid ? "Email is valid." : "Email is not a valid format.";

        return {
          resultIsValid : isValid,
          message : message
        };
    }

    saveNotification() {
        const dc = this;

        const notificationDetails = {
            email: $('#email').val(),
            notification_type: $('#notificationType').val(),
        };

        if(this.editingId){
            notificationDetails.id = this.editingId;
        }

        // console.log("notificationDetails", notificationDetails);

        const isValidResult = this.notificationIsValid(notificationDetails.email);
        if(isValidResult.resultIsValid){
            return $.ajax({
                type: (notificationDetails.id ? "PUT" : "POST"),
                url: API_HOST + "/notification_data",
                data: notificationDetails,
                success: function(model, result, xhr){
                    $('#notificationModalContainer').modal('hide');

                    dc.clearModalFields();
                    
                    dc.mainGrid.jsGrid("loadData");
                },
                error: function(result, xhr){
                    $('#logModalContainer').modal('hide');
                    alert("There was an error entering the data.  If tihs issue continues, please contact the site administror at softwareengineeringsupport@geoengineers.com.")
                    dc.mainGrid.jsGrid("loadData");
                }
            });
        }else{
            // console.log("isValidResult", isValidResult);
            alert(isValidResult.message);
        }

        return false;
    }

    clearModalFields() {
        $('#email').val("");
        $('#notification_type').val("");  

        this.editingId = null;
        return false;
    }

    render() {
        return <div className="grid-default-dimensions">
            
            <div id="notification-container" className="container-fluid" ref={this.gridRef}></div>

            <div className="container-fluid" style={{ textAlign:"right" }}>
                <button type="button" className="btn btn-primary" data-toggle="modal" onClick={this.showAddNotification.bind(this)}> {/*  data-target="#notificationModalContainer" */}
                    Add new notification
                </button>
            </div>

            <div className="modal" tabIndex="-1" id="detailsModalContainer"  role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body" id="detailsModalBody">
                            No content
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="cancelNotification">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal" tabIndex="-1" id="notificationModalContainer"  role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Enter Notification Details</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-4">Email:</div>
                                <div className="col-8"><input type="text" name="email" id="email" style={{ width:"250px" }} /></div>
                            </div>
                            <div className="row">
                                <div className="col-4">Notification Type:</div>
                                <div className="col-8">
                                    <select name="notificationType" id="notificationType" style={{ width:"250px" }}>
                                        <option value="candidate_references">Candidate References Saved</option>
                                        <option value="references_responded">Reference Responded</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" id="saveNotification" onClick={this.saveNotification.bind(this)}>Save changes</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="cancelNotification">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>;
    }
}  

export default Notification;